<template>
    <div>
        <fromSearch :object_='seriesList_' @searchFun='getList' ref='fromSearch_' />
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange" >
            <template #menu-left>
                <el-button type="primary" @click="downBlobFileFun(fromSearch_.returnParm(),page)">导出</el-button>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
        </avue-crud>   
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    import { accountWater_ } from '@/const/from/record/merchantWalletNft.js'
    import fromSearch from '@/components/fromSearch.vue'
    import { accountWater } from '@/const/crud/record/merchantWalletNft'
    import mixins from '@/views/mixins/page'
    import { turnTimeFun } from '@/utils/util.js'
    let pageObj = mixins(['getAccountWaterNft'])
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj
    
    import mixins1 from '@/views/mixins/common'
    let commonFun = mixins1('accountWaterNft');const { downBlobFileFun } = commonFun
    const fromSearch_ = ref(null)

    const tableOption = ref({}); tableOption.value = accountWater
    const seriesList_ = ref(accountWater_);
    getList(1)
</script>

<style lang="scss" scoped>
    :deep(.el-dialog__header){
        margin-bottom: 0;
    }
    :deep(.el-dialog__body){
        padding-top: 10px;
    }
</style>